/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* ios safe areas */

// :root {
//   --ion-safe-area-top: 20px;
//   --ion-safe-area-bottom: 22px;
// }

/* Set font app wide */

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Atma';
    src: url('/assets/fonts/Atma/Atma-SemiBold.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/fonts/Inter/static/Inter-SemiBold.ttf') format('truetype'); //url('../fonts/Lato-Light.eot') format('embedded-opentype'), url('../fonts/Lato-Light.woff') format('woff'),
    font-weight: 600;
    font-style: normal;
}

* {
    font-family: 'Montserrat';
}

:focus {
    outline: none;
}


/* start ionic issue correction */

ion-item {
    --padding-start: 0px !important;
    --inner-padding-end: 10px !important;
}

ion-button {
    margin-top: 16px;
}

ion-list {
    ion-list-header {
        padding-inline-start: 0;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
        ion-label {
            padding-inline-start: 0;
            margin-top: 10px;
            font-size: 18px;
            font-weight: 700;
        }
    }
    ion-item {
        ion-avatar {
            font-size: 40px;
            color: var(--ion-color-medium);
        }
    }
}

.ion-item {
    --inner-padding-start: 10px;
    --inner-padding-end: 10px;
    --padding-start: 0px;
}

ion-toolbar {
    .sc-ion-searchbar-ios-h {
        padding-top: 0;
        padding-bottom: 0;
    }
}


/* end ionic issue correction */

hr {
    background: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.hero-content {
    padding: 16px;
    background-color: aliceblue;
    margin-bottom: 24px;
    p {
        margin: 0;
    }
    .title {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 18px;
        margin-bottom: 4px;
    }
}

.middle-center {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.grayout {
    filter: grayscale(100%);
}

.long-alert {
    --width: 90%;
    p {
        font-size: 18px;
        color: black;
    }
}

.hide {
    display: none;
}

.custom-popover .popover-content {
    width: 80%;
    //padding: 16px;
    // top: 70px;
    // left: 30px;
    // bottom: 70px
}

$breakpoint-phone: 576px;
@media (max-width: $breakpoint-phone) {
    .login-form {
        height: 300px;
    }
}

@media (min-width: $breakpoint-phone) {
    .login-form {
        height: 100vh;
    }
}

ion-searchbar {
    .searchbar-input-container {
        .searchbar-input.sc-ion-searchbar-md {
            box-shadow: none !important;
        }
    }
}

.header-ios ion-toolbar:last-of-type {
    --border-width: 0 0 0 0;
}
